<template>
	<div class="box">
		<div class="tit">
			<div class="val">{{ title }}</div>
			<div class="descr">通知时间：{{ noticeTime }}</div>
		</div>

		<div class="lists" v-if="resultList">
			<table>
				<thead>
					<tr>
						<th colspan="6" class="tit">{{ params.month }}月年终考核结果</th>
					</tr>
					<tr>
						<th colspan="3">申报人：{{ resultList[0].staffs.name }}</th>
						<th colspan="3">申报时间：{{ resultList[0].createdAt }}</th>
					</tr>
					<tr>
						<th width="20px">序号</th>
						<th>考核项</th>
						<th width="30px">申报结果</th>
						<th width="30px">审核结果</th>
						<th>所得金额</th>
						<th>查看详情</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in resultList" :key="index">
						<td>{{ index + 1 }}</td>
						<td>
							{{ item.ljYearRules.itemName }}
						</td>
						<td>{{ item.isComplete | show_isComplete_text }}</td>
						<td>{{ item.auditIsComplete | show_isComplete_text }}</td>
						<td>{{ item.auditIsComplete == 1 ? item.auditMoney : 0 }}</td>
						<td @click="showDetails(item)">
							<van-tag plain type="primary">查看详情</van-tag>
						</td>
					</tr>
					<tr>
						<td colspan="4">合计</td>
						<td>{{ countMoney }}</td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>

		<div
			class="btns"
			v-if="quesionsInfo && quesionsInfo.staffsAgreeState == -1"
		>
			<van-button type="info" block @click="clickStaffsAgree"
				>确认无误</van-button
			>
		</div>
		<div class="agree_show" v-else>您已经同意了审核结果</div>

		<van-popup
			safe-area-inset-bottom
			round
			closeable
			v-model="show"
			style="width:80vw;max-height: 80vh;"
			class="audit-info"
		>
			<div class="tit" style="text-align:center;">审核详情</div>
			<van-cell-group v-if="detailsInfo">
				<van-cell :value="detailsInfo.ljYearRules.standardName" />
				<van-cell
					title="审核结果"
					:value="detailsInfo.auditIsComplete | show_isComplete_text"
				/>
				<van-cell title="完成数量" :value="detailsInfo.auditCompleteNumber" />
				<van-cell
					title="考核金额"
					:value="detailsInfo.auditIsComplete == 1 ? detailsInfo.auditMoney : 0"
				/>
				<van-cell title="审核说明" :value="detailsInfo.auditMark" />
				<van-cell title="审核人" :value="detailsInfo.auditStaffName" />
				<van-cell title="审核时间" :value="detailsInfo.auditTime" />
				<div class="pics" v-if="detailsInfo.auditUploads">
					<van-image
						width="100"
						height="100"
						:src="pic"
						v-for="(pic, k) in detailsInfo.auditUploads.split(',')"
						:key="k"
					/>
				</div>
			</van-cell-group>
		</van-popup>
	</div>
</template>

<script>
import {
	Toast,
	Button,
	Popup,
	Tag,
	Cell,
	CellGroup,
	Image,
	Dialog
} from 'vant';
import { getResultInfo, staffsAgree, getQuestionDetail } from '@/api/result';
import wx from 'weixin-js-sdk';
export default {
	components: {
		[Button.name]: Button,
		[Popup.name]: Popup,
		[Tag.name]: Tag,
		[Cell.name]: Cell,
		[CellGroup.name]: CellGroup,
		[Image.name]: Image
	},
	data() {
		return {
			title: '考核审批结果通知',
			noticeTime: null,
			params: {
				id: null,
				month: null,
				staffid: null
			},
			show: false,
			resultList: null, //获取当前月份-列表数据。
			detailsInfo: null, //详情
			quesionsInfo: null, //主表详情
			countMoney: null //总金额
		};
	},
	filters: {
		show_isComplete_text: function(v) {
			if (v == -1) {
				return '否';
			} else {
				return '是';
			}
		}
	},
	methods: {
		//获取主表问卷 详情。
		getQuestionInfo() {
			getQuestionDetail({ questionsId: this.params.id }).then((res) => {
				if (res.status == 200) {
					this.quesionsInfo = res.data.data;
					console.log('quesionsInfo', this.quesionsInfo);
				}
			});
		},
		//获取当前月份-列表数据。
		getResuldInfoByid() {
			let params = {
				month: this.params.month,
				questionsId: this.params.id,
				staffId: this.params.staffid
			};
			console.log('params', params);
			let _self = this;
			getResultInfo(params).then((res) => {
				console.log('res', res);
				if (res?.status == 200 && res?.data?.code == 200) {
					_self.resultList = res.data.data;
					//计算总金额
					let countMoney = 0;
					_self.resultList.map((item) => {
						countMoney = countMoney + item.auditMoney;
					});
					_self.countMoney = countMoney;
					if (_self.resultList.length <= 0) {
						Dialog.alert({
							message: '获取信息错误~'
						}).then(() => {
							wx.closeWindow();
						});
					}
				} else {
					Dialog.alert({
						message: '网络错误~'
					}).then(() => {
						wx.closeWindow();
					});
				}
			});
		},

		//显示详情的接口
		showDetails(item) {
			this.show = true;
			this.detailsInfo = item;
		},

		//员工确认 - 同意审核
		clickStaffsAgree() {
			Dialog.confirm({
				title: '提示',
				message: '是否确认审核结果'
			})
				.then(() => {
					Toast.loading({
						message: '请稍等...',
						forbidClick: true,
						duration: 0
					});
					let postParams = {
						staffsId: this.params.staffid,
						questionsId: this.params.id
					};
					staffsAgree(postParams).then((res) => {
						Toast.clear();
						console.log('res', res);
						if (res?.status == 200 && res?.data?.code == 200) {
							Dialog.alert({
								title: '提示',
								message: res.data.msg
							}).then(() => {
								// on close
								wx.closeWindow();
							});
						}
					});

					// on confirm
				})
				.catch(() => {
					// on cancel
				});
		}
	},
	created() {
		let id = this.$route.query.id; //问卷的id
		let month = this.$route.query.month;
		let staffid = this.$route.query.staffid;
		let title = this.$route.query.title; //标题
		let noticeTime = this.$route.query.noticeTime; //时间
		(this.title = title), (this.noticeTime = noticeTime);
		this.params = {
			id: id,
			month: month,
			staffid: staffid
		};

		console.log('id', id);
		this.getResuldInfoByid(); //获取当前月份-列表数据。
		this.getQuestionInfo(); //获取主表问卷
	}
};
</script>

<style scoped>
/deep/ .van-cell__value {
	/* color: #1989fa; */
}
.agree_show {
	text-align: center;
	color: blue;
	line-height: 240%;
	margin: 40px 0;
	background: #f5f5f5;
	border: 1px solid #ccc;
}
.box {
	padding: 20px;
	min-height: 100vh;
	box-sizing: border-box;
}

.box .tit {
	padding: 20px 0;
	font-size: 32px;
	font-weight: bold;
}

.box .tit .descr {
	font-size: 24px;
	font-weight: 400;
	color: #999;
	margin-top: 10px;
}

.lists {
	margin-top: 20px;
	margin-bottom: 150px;
}

.lists table {
	width: 100%;
	border-collapse: collapse;
	font-size: 24px;
}

.lists table thead {
	background-color: #f6f7fc;
}

.lists table td,
.lists table th {
	border: #ddd 1px solid;
	text-align: center;
	padding: 10px 0;
}

.lists table th {
	font-weight: 400;
}

.lists table th.tit {
	font-weight: bold;
}

.btns {
	position: fixed;
	bottom: 0;
	left: 0;
	padding: 50px;
	width: 100%;
	box-sizing: border-box;
}

.audit-info .pics {
	padding: 20px;
}
</style>
