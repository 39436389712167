import request from '@/config/http';

// 获取问卷的详细记录。
export function getResultInfo(data) {
	return request({
		url: '/questions/questionsDetailsList',
		method: 'get',
		params: data
	});
}

// 员工同意 - 问卷审核的结果。
export function staffsAgree(data) {
	return request({
		url: '/weixin-mp/oa/staffs-questions-agree',
		method: 'get',
		params: data
	});
}

//查询问卷详情
export function getQuestionDetail(data) {
	return request({
		url: '/questions/questionDetail',
		method: 'get',
		params: data
	});
}
